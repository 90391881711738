import React, { Fragment } from "react";
import "./Gameboard.css"; // Add any specific styles for the gameboard
import GameboardWord from "./GameboardWord";

function Gameboard({
  sentence,
  letterMapping,
  selectedIndex,
  onSelectLetter,
  onGuessLetter,
  initialSentence,
  difficulty,
  zoomLevel,
  wrongIndex,
}) {
  const words = sentence.join("").split(" ");
  let ctr = 0;

  const initialWords = initialSentence.split(" ");

  let gameBoardStyle = {};
  if (zoomLevel !== undefined && zoomLevel !== "100") {
    let zoomAmount = `${zoomLevel}%`;
    gameBoardStyle = {
      zoom: zoomAmount,
    };
  }

  function WhiteSpace(wordIndex) {
    if (wordIndex !== words.length - 1) {
      return <div className="whitespace"></div>;
    }
  }

  return (
    <div className={"gameboard"} style={gameBoardStyle}>
      {words.map((word, wordIndex) => {
        if (wordIndex > 0) {
          ctr++;
        }

        const ctrBeforeWord = ctr;
        ctr += word.length;

        return (
          <Fragment key={`gw-${wordIndex}`}>
            <GameboardWord
              word={word}
              initialWord={initialWords[wordIndex]}
              initialSentence={initialSentence}
              difficulty={difficulty}
              sentence={sentence}
              ctr={ctrBeforeWord}
              letterMapping={letterMapping}
              selectedIndex={selectedIndex}
              onSelectLetter={onSelectLetter}
              wrongIndex={wrongIndex}
            />
            {WhiteSpace(wordIndex)}
          </Fragment>
        );
      })}
    </div>
  );
}

export default Gameboard;
