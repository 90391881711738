import React from "react";
import "./Gameboard.css"; // Add any specific styles for the gameboard
import { Difficulties } from "../settings/DifficultySlider";

// Function to check if any occurrences of a letter have been guessed but not all
const notAllOccurrencesGuessed = (letter, sentence, initialSentence) => {
  const upperLetter = letter.toUpperCase();
  const totalOccurrences = initialSentence
    .split("")
    .filter((char) => char.toUpperCase() === upperLetter).length;
  const guessedOccurrences = sentence
    .split("")
    .filter((char) => char.toUpperCase() === upperLetter).length;

  return guessedOccurrences < totalOccurrences;
};

const hasSomeOccurrencesGuessed = (letter, sentence, initialSentence) => {
  const upperLetter = letter.toUpperCase();
  const totalOccurrences = initialSentence
    .split("")
    .filter((char) => char.toUpperCase() === upperLetter).length;
  const guessedOccurrences = sentence
    .split("")
    .filter((char) => char.toUpperCase() === upperLetter).length;

  return guessedOccurrences > 0 && guessedOccurrences < totalOccurrences;
};

function GameboardLetterSubscript({
  originalLetter,
  difficulty,
  letterMapping,
  initialSentence,
  sentence,
}) {
  const isEasyNumber = (shouldShowNumber, hasSomeGuessed, originalLetter) => {
    if (shouldShowNumber) {
      if (difficulty === Difficulties.Easy && hasSomeGuessed) {
        return (
          <span className="letter-number easy">
            {letterMapping[originalLetter.toUpperCase()]}
          </span>
        );
      } else {
        return (
          <span className="letter-number">
            {letterMapping[originalLetter.toUpperCase()]}
          </span>
        );
      }
    }
  };

  let shouldShowNumber;
  let hasSomeGuessed;

  // optimize this later...
  if (
    difficulty === Difficulties.Easy ||
    difficulty === Difficulties.Medium ||
    difficulty === Difficulties.Advanced
  ) {
    shouldShowNumber = notAllOccurrencesGuessed(
      originalLetter,
      sentence.join(""),
      initialSentence
    );
    hasSomeGuessed = hasSomeOccurrencesGuessed(
      originalLetter,
      sentence.join(""),
      initialSentence
    );
  } else if (
    difficulty === Difficulties.Hard ||
    difficulty === Difficulties.Challenging ||
    difficulty === Difficulties.Expert ||
    difficulty === Difficulties.Hardcore
  ) {
    shouldShowNumber = hasSomeOccurrencesGuessed(
      originalLetter,
      sentence.join(""),
      initialSentence
    );
  }

  return isEasyNumber(shouldShowNumber, hasSomeGuessed, originalLetter);
}

export default GameboardLetterSubscript;
