import React, { useEffect } from "react";
import "./ThemeToggle.css";

function HeaderToggle({ hideHeader, onToggle }) {
  function handleBlur() {
    this.blur();
  }

  useEffect(() => {
    const ref = document.getElementById("topbar-input");
    ref.addEventListener("change", handleBlur);

    return () => {
      ref.removeEventListener("change", handleBlur);
    };
  });

  return (
    <div className="slider-toggle topbar-toggle">
      <span className="theme-icon">{hideHeader ? "📖" : "📗"}</span>
      <label className="toggle-switch">
        <input
          id="topbar-input"
          type="checkbox"
          checked={hideHeader}
          onChange={() => onToggle()}
        />
        <span className="slider"></span>
      </label>
    </div>
  );
}

export default HeaderToggle;
