// utils.js

import { Difficulties } from "../settings/DifficultySlider";

/*
  Used for styling special characters "higher" or "lower" in the view.
 */
export const SPECIAL_CHARS_UPPER = "'*\"";
export const SPECIAL_CHARS_MIDDLE = ";:!?()&-—1234567890";
export const SPECIAL_CHARS_LOWER = " ,.";

export const SPECIAL_CHARS = (
  SPECIAL_CHARS_UPPER +
  SPECIAL_CHARS_MIDDLE +
  SPECIAL_CHARS_LOWER
).split("");

export function createInitialSentence(initialSentence, difficulty) {
  let origSentenceNoSpecialChars = initialSentence.replace(/[^a-zA-Z]/g, "");
  let numberOfBlanks = Math.floor(origSentenceNoSpecialChars.length * 0.7);
  let naiveSlotsToReveal = origSentenceNoSpecialChars.length - numberOfBlanks;
  let slotsToReveal = 5;

  if (difficulty === Difficulties.Easy) {
    slotsToReveal = 9;
  } else if (difficulty === Difficulties.Medium) {
    slotsToReveal = 7;
  } else if (
    difficulty === Difficulties.Expert ||
    difficulty === Difficulties.Hardcore
  ) {
    slotsToReveal = 3;
  }

  if (naiveSlotsToReveal < slotsToReveal) {
    slotsToReveal = naiveSlotsToReveal;
  }

  let chars = [];

  const re = /[a-zA-Z]/;

  let modifiedSentence = initialSentence.split("");
  for (let i = 0; i < initialSentence.length; i++) {
    if (re.test(initialSentence[i])) {
      chars.push(i);
      modifiedSentence[i] = "_";
    }
  }

  shuffle(chars);

  for (let i = 0; i < slotsToReveal; i++) {
    let index = chars[i];
    modifiedSentence[index] = initialSentence[index];
  }

  return modifiedSentence;
}

export function generateRandomMapping() {
  const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
  const numbers = Array.from({ length: 26 }, (_, i) => i + 1);

  // Shuffle numbers array to create random mapping
  for (let i = numbers.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [numbers[i], numbers[j]] = [numbers[j], numbers[i]];
  }

  // Create the mapping object
  const mapping = {};
  letters.forEach((letter, index) => {
    mapping[letter] = numbers[index];
  });

  return mapping;
}

export function findNextBlank(sentence, selectedIndex, direction = 1) {
  const s = sentence.join("");
  let blankIndex;

  if (selectedIndex == null) {
    // Start searching for the next blank from the beginning
    blankIndex = direction === 1 ? s.indexOf("_", 0) : s.lastIndexOf("_");
  } else {
    // Determine the start index based on the direction
    const startIndex =
      direction === 1
        ? selectedIndex + 1
        : (s.length + selectedIndex - 1) % s.length;

    // Find the next or previous blank based on the direction
    blankIndex =
      direction === 1
        ? s.indexOf("_", startIndex)
        : s.lastIndexOf("_", startIndex);
  }

  // If no blank is found, wrap around to the start
  if (blankIndex === -1) {
    blankIndex = direction === 1 ? s.indexOf("_") : s.lastIndexOf("_");
  }

  return blankIndex;
}

function truncateDecimals(number, digits) {
  var multiplier = Math.pow(10, digits),
    adjustedNum = number * multiplier,
    truncatedNum = Math[adjustedNum < 0 ? "ceil" : "floor"](adjustedNum);

  return truncatedNum / multiplier;
}

export function getNiceTime(first, second) {
  let delta = (first - second) / 1000;

  let time = "";

  let minutes;
  if (delta > 60) {
    minutes = Math.floor(delta / 60);
    time = `${minutes}m `;
  }

  let seconds = truncateDecimals(delta % 60, 3);
  time += `${seconds}s`;

  return time;
}

export function formatIsoDate(date) {
  let day = date.getDate();
  if (day < 10) {
    day = "0" + day;
  }
  let month = date.getMonth() + 1;
  if (month < 10) {
    month = "0" + month;
  }

  return `${date.getFullYear()}-${month}-${day}`;
}

export function printLives(current, total) {
  if (total === undefined) {
    return "♾️";
  }

  let s = "";

  for (let i = 0; i < current; i++) {
    s += "💚";
  }

  for (let i = 0; i < total - current; i++) {
    s += "❤️";
  }

  return s;
}

export function isAlphabetic(char) {
  return /[a-zA-Z]/.test(char);
}

function shuffle(array) {
  let currentIndex = array.length;

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    let randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }
}

export function getUniqueLowestDifficulty(input) {
  const difficulties = "😊😄🥳😎😲😱☠️";

  for (let char of difficulties) {
    if (input.includes(char)) {
      return char;
    }
  }
  return "";
}
