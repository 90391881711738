import React from "react";
import "./Gameboard.css"; // Add any specific styles for the gameboard
import {
  SPECIAL_CHARS_UPPER,
  SPECIAL_CHARS_MIDDLE,
  SPECIAL_CHARS_LOWER,
  isAlphabetic,
} from "../helpers/utils";

function GameboardLetter({
  letter,
  gindex,
  selectedIndex,
  onSelectLetter,
  wrongIndex,
}) {
  if (isAlphabetic(letter) || letter === "_") {
    return (
      <div className="regular">
        <button
          onClick={() => onSelectLetter(gindex)}
          disabled={letter !== "_"}
          className={
            (selectedIndex === gindex ? "selected" : "") +
            (wrongIndex === gindex ? " wrong" : "")
          }
        >
          {letter.toUpperCase()}
        </button>
      </div>
    );
  } else if (SPECIAL_CHARS_UPPER.includes(letter)) {
    return (
      <div className="special upper">
        <div>{letter}</div>
      </div>
    );
  } else if (SPECIAL_CHARS_MIDDLE.includes(letter)) {
    return (
      <div className="special middle">
        <div>{letter}</div>
      </div>
    );
  } else if (SPECIAL_CHARS_LOWER.includes(letter)) {
    return (
      <div className="special lower">
        <div>{letter}</div>
      </div>
    );
  } else {
    if (process.env["NODE_ENV"] === "development") {
      console.warn("FOUND UNHANDLED CHARACTER:", letter);
    }

    return (
      <div className="special middle">
        <div>{letter}</div>
      </div>
    );
  }
}

export default GameboardLetter;
