import { useEffect } from "react";

// See: https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event

const PreventReload = () => {
  const beforeUnloadHandler = (event) => {
    // Recommended
    event.preventDefault();

    // Included for legacy support, e.g. Chrome/Edge < 119
    event.returnValue = true;
  };

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      window.addEventListener("beforeunload", beforeUnloadHandler);

      // Cleanup: remove script if the component unmounts
      return () => {
        window.removeEventListener("beforeunload", beforeUnloadHandler);
      };
    }
  }); // Empty dependency array ensures this runs only once

  return null; // This component doesn't render anything
};

export default PreventReload;
