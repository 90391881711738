import React from "react";
import "./Gameboard.css"; // Add any specific styles for the gameboard
import GameboardLetter from "./GameboardLetter";
import GameboardLetterSubscript from "./GameboardLetterSubscript";
import { isAlphabetic } from "../helpers/utils";
import { splitWordWithDelimiters } from "../helpers/textHelpers";

function GameboardWord({
  word,
  initialWord,
  initialSentence,
  difficulty,
  sentence,
  ctr,
  letterMapping,
  selectedIndex,
  onSelectLetter,
  wrongIndex,
}) {
  const hyphenated = splitWordWithDelimiters(word);
  const hyphenatedInitial = splitWordWithDelimiters(initialWord);

  return hyphenated.map((word, subwordIndex) => {
    return (
      <div key={`wc-${subwordIndex}`} className="word-container">
        {word.split("").map((letter, index) => {
          const ctrBeforeLetter = ctr++;

          const originalLetter = hyphenatedInitial[subwordIndex][index];

          return (
            <div
              key={`lc-${subwordIndex}-${ctrBeforeLetter}`}
              className="letter-container"
            >
              <GameboardLetter
                letter={letter}
                gindex={ctrBeforeLetter}
                selectedIndex={selectedIndex}
                onSelectLetter={onSelectLetter}
                wrongIndex={wrongIndex}
              />
              {(isAlphabetic(letter) || letter === "_") && (
                <GameboardLetterSubscript
                  initialSentence={initialSentence}
                  sentence={sentence}
                  originalLetter={originalLetter}
                  difficulty={difficulty}
                  letterMapping={letterMapping}
                />
              )}
            </div>
          );
        })}
      </div>
    );
  });
}

export default GameboardWord;
