import { useEffect } from "react";

function HWKeyboardState({ setEnableHwKeyboard }) {
  useEffect(() => {
    let enableHWKeyboard;

    //migrateTypo, remove after 2024-10-01
    const oldPref = localStorage.getItem("enableHWKeyboad");
    if (oldPref !== null && oldPref !== "undefined") {
      localStorage.setItem("enableHWKeyboard", oldPref);
      localStorage.removeItem("enableHWKeyboad");
    }

    const savedPreference = localStorage.getItem("enableHWKeyboard");
    if (savedPreference !== null && savedPreference !== "undefined") {
      enableHWKeyboard = JSON.parse(savedPreference);
    } else {
      enableHWKeyboard = true;
    }

    localStorage.setItem("enableHWKeyboard", JSON.stringify(enableHWKeyboard));
    setEnableHwKeyboard(enableHWKeyboard);
  }, [setEnableHwKeyboard]);

  return null;
}

export default HWKeyboardState;
