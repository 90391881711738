import React from "react";
import ExplanationWrapper from "./ExplanationWrapper";

function Difficulties() {
  return (
    <ExplanationWrapper title="Difficulties">
      <p>
        Difficulties have no effect on the sentences you have to guess, but on
        how much information you get.
      </p>
      <p>Changing the difficulty will restart a level unless it's completed.</p>
      <p>
        <strong>Easy 😊</strong>: All letters have numbers. Numbers are colored
        if the letter has been guessed. Keyboard is colored for guessed letters
        and keys are disabled when a letter is completely guessed. Mistakes are
        allowed.
      </p>
      <p>
        <strong>Medium 😄</strong>: All letters have numbers. Keyboard is
        colored for guessed letters and keys are disabled when a letter is
        completely guessed. Two mistakes are allowed.
      </p>
      <p>
        <strong>Advanced 🥳</strong>: All letters have numbers. Keyboard is
        colored for guessed letters and all keys are enabled in the beginning.
        Keys become disabled when (if you can) place them all in the sentence.
        Two mistakes are allowed.
      </p>
      <p>
        <strong>Hard 😎</strong>: Guessed letters have numbers. Keyboard is
        disabled when a letter is completely guessed. Two mistakes are allowed.
      </p>
      <p>
        <strong>Challenging 😲</strong>: Guessed letters have numbers. Keyboard
        is active for all letters and only become disabled if you can place them
        all in the sentence. Two mistakes are allowed.
      </p>
      <p>
        <strong>Expert 😱</strong>: Guessed letters have numbers. Keyboard is
        always active. Two mistakes are allowed.
      </p>
      <p>
        <strong>Hardcore ☠️</strong>: Guessed letters have numbers. Keyboard is
        always active. No mistakes are allowed.
      </p>
    </ExplanationWrapper>
  );
}

export default Difficulties;
