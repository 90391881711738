import { useEffect, useState } from "react";
import axios from "axios";
import { formatIsoDate, getUniqueLowestDifficulty } from "../helpers/utils";

const ThemeManager = ({ onThemesLoaded, onSentencesLoaded, selectedTheme }) => {
  const [themes, setThemes] = useState([]);

  const BACKEND_ADDR = process.env["REACT_APP_BACKEND_ADDR"] || "/server";

  useEffect(() => {
    // Fetch the themes from the Express server

    let data = {};
    const uuid = localStorage.getItem("uuid");
    if (uuid !== null && uuid !== "undefined") {
      data.uid = JSON.parse(uuid);
    }

    axios
      .post(`${BACKEND_ADDR}/themes`, data)
      .then((response) => {
        if (response.data.error === undefined) {
          setThemes(response.data);
        } else {
          console.log(response.data.error);
          setThemes(undefined);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the themes!", error);
      });
  }, [BACKEND_ADDR]);

  useEffect(() => {
    if (selectedTheme === undefined) return;

    if (selectedTheme.sentences === undefined) {
      // console.log("current theme has no sentences yet");

      const data = {
        id: selectedTheme.uuid,
      };
      axios
        .post(`${BACKEND_ADDR}/theme`, data)
        .then((response) => {
          if (response.data.error === undefined) {
            onSentencesLoaded(response.data.sentences, selectedTheme);
          } else {
            onSentencesLoaded(undefined, undefined);
          }
        })
        .catch((error) => {
          console.error("There was an error fetching the themes!", error);
        });
    }
  }, [selectedTheme, BACKEND_ADDR, onSentencesLoaded]);

  useEffect(() => {
    async function fetchThemes() {
      if (themes === undefined) {
        onThemesLoaded(undefined);
        return;
      }

      if (themes.length === 0) return;

      try {
        let themesObj = {};

        for (const uuid in themes) {
          try {
            const theme = themes[uuid];

            let after = Date.parse(theme.after);

            if (isNaN(after)) {
              throw new Error("Failed to parse time");
            }
            const d = new Date(after);
            const isoDate = formatIsoDate(d);

            if (theme.chapterName === undefined) {
              themesObj[`${isoDate}: ${theme.name}`] = {
                uuid: uuid,
                sentences: theme.sentences,
                completed: theme.completed,
              };
            } else {
              let themeIndex = `Book: ${theme.name}`;
              if (themesObj[themeIndex] === undefined) {
                themesObj[themeIndex] = {
                  chapters: {},
                };
              }

              if (theme.part === undefined) {
                themesObj[themeIndex].chapters[theme.chapterName] = {
                  uuid: uuid,
                  sentences: theme.sentences,
                  completed: theme.completed,
                };
              } else {
                if (
                  themesObj[themeIndex].chapters[theme.chapterName] ===
                  undefined
                ) {
                  themesObj[themeIndex].chapters[theme.chapterName] = {
                    parts: {},
                  };
                }
                themesObj[themeIndex].chapters[theme.chapterName].parts[
                  `${isoDate} - (${theme.part})`
                ] = {
                  uuid: uuid,
                  sentences: theme.sentences,
                  completed: theme.completed,
                };
              }
            }
          } catch (err) {
            console.log("Error parsing after for", uuid);
          }
        }

        bubbleDifficulties(themesObj);

        onThemesLoaded(themesObj); // Notify parent that themes are loaded
      } catch (error) {
        console.error("Error loading themes:", error);
      }
    }

    if (themes.length !== 0) {
      fetchThemes();
    }
  }, [onThemesLoaded, themes]);

  return null;
};

/**
 * look for the lowest "chapter" or "chapter part",
 * cycle their difficulties until they either fail,
 * or all is read.
 * Assign the lowest all are completed on, to the parent.
 *
 * Since it specifically assigns the "completed"-attributes,
 * this function is idempotent.
 * @param {*} themesObj
 */

function bubbleDifficulties(themesObj) {
  for (const theme in themesObj) {
    if (themesObj[theme].chapters !== undefined) {
      let completedBook = true;
      let completionsBook = "";
      for (const chapterKey in themesObj[theme].chapters) {
        const chapter = themesObj[theme].chapters[chapterKey];
        if (chapter.parts !== undefined) {
          // has parts
          let completedChapter = true;
          let completionsChapter = "";

          for (const partKey in chapter.parts) {
            const part = chapter.parts[partKey];
            if (part.completed === undefined) {
              completedBook = false;
              completedChapter = false;
              break;
            } else {
              completionsBook += part.completed;
              completionsChapter += part.completed;
            }
          }
          if (completedChapter) {
            chapter.completed = getUniqueLowestDifficulty(completionsChapter);
          }
        } else {
          // no parts, just one

          if (chapter.completed === undefined) {
            completedBook = false;
          } else {
            completionsBook += chapter.completed;
          }
        }
      }
      if (completedBook) {
        themesObj[theme].completed = getUniqueLowestDifficulty(completionsBook);
      }
    }
  }
}

export default ThemeManager;
