/**
 * Breaks long words into a list. Prioritizes hyphens.
 * The list has information about whether it was broken on an existing hyphen
 * @param {*} word
 * @param {*} maxChunkLength
 * @returns
 */
export function breakWord(word, maxChunkLength = 10) {
  // Split by existing hyphens first, keeping them with the preceding part
  let parts = word.split("-");
  let result = [];

  for (let i = 0; i < parts.length; i++) {
    let part = parts[i];

    // If not the last part from a hyphen split, keep the hyphen with the current chunk
    if (i < parts.length - 1) {
      part += "-";
    }

    // Break each part further if it exceeds maxChunkLength
    while (part.length > maxChunkLength) {
      let breakIndex = maxChunkLength;

      // Look for a syllable break before the maxChunkLength
      for (let j = maxChunkLength; j > 1; j--) {
        if (/[aeiouy]+[^aeiouy]*$/.test(part.slice(0, j))) {
          breakIndex = j;
          break;
        }
      }

      // Add the chunk with a new hyphen and mark as newly hyphenated
      result.push({
        chunk: part.slice(0, breakIndex) + "-",
        wasExistingHyphen: false,
      });
      part = part.slice(breakIndex);
    }

    // Add the remaining part, with information on existing hyphen
    if (i < parts.length - 1) {
      result.push({ chunk: part, wasExistingHyphen: true });
    } else {
      // For the last part, only add a hyphen if it was a forced break
      if (part.length > maxChunkLength) {
        result.push({ chunk: part + "-", wasExistingHyphen: false });
      } else {
        result.push({ chunk: part, wasExistingHyphen: false });
      }
    }
  }

  return result;
}

// Example Usage:

if (process.env["NODE_ENV"] === "development") {
  // console.log(breakWord("unbelievably-complex", 5));
  // console.log(breakWord("unbelievabl-ycom"));
}

/**
 * Split long words.
 * the dash is at the beginning of the word,
 * but the em dashes are at the beginning of the next.
 *
 * what-is—this => ["what-", "is", "—this"]
 * @param {*} word
 * @returns
 */
export function splitWordWithDelimiters(word) {
  if (typeof word !== "string") return [];

  const parts = word.split(/(-|—)/);

  const result = [];
  for (let i = 0; i < parts.length; i++) {
    const current = parts[i];
    const next = parts[i + 1];

    if (current === "-" && result.length > 0) {
      // Append hyphen to the previous part
      result[result.length - 1] += current;
    } else if (current === "—" && next) {
      // Prepend em dash to the next part
      parts[i + 1] = current + next;
    } else if (current === "—" && next === "") {
      // if next is empty, add the emdash
      result.push(current);
    } else if (current !== "-" && current !== "—") {
      // Add word parts to the result
      result.push(current);
    }
  }

  return result;
}
